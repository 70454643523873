<template>

<ul class="text-center">
  <li><router-link to="/scrolltriggernr1"  class="hover:text-red-600">Example Nr.1</router-link></li>
  <li><router-link to="/scrolltriggernr2"  class="hover:text-red-600">Example Nr.2</router-link></li>
  <li><a href="https://codepen.io/jakewhiteleydev/pen/VwaXZZV"  class="hover:text-red-600">Example Nr.3</a></li>

</ul>



</template>

<script>
export default {
  name: "ScrollTriggerPlugin"
}
</script>

<style scoped>

</style>